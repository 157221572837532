<template>
  <div>
    <el-data-table ref="table" v-bind="tableConfig" :columns="columns">
    </el-data-table>

    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :before-close="closeDialog"
    >
      <el-form :model="form" :rules="rules" ref="ruleUser">
        <el-form-item
          label="序号"
          prop="id"
          :label-width="formLabelWidth"
          v-if="false"
        >
          <el-input
            placeholder="请输入序号"
            clearable
            autocomplete="off"
            v-model="form.id"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="订单号"
          prop="orderid"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入订单号"
            clearable
            autocomplete="off"
            v-model="form.orderid"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="车票id"
          prop="ticketid"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入车票id"
            clearable
            autocomplete="off"
            v-model="form.ticketid"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="车辆类型"
          prop="cartype"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入车辆类型"
            clearable
            autocomplete="off"
            v-model="form.cartype"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="用户id"
          prop="userid"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入用户id"
            clearable
            autocomplete="off"
            v-model="form.userid"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="用户邮箱"
          prop="useremail"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入用户邮箱"
            clearable
            autocomplete="off"
            v-model="form.useremail"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="商品id"
          prop="goodsid"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入商品id"
            clearable
            autocomplete="off"
            v-model="form.goodsid"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="商品套餐id"
          prop="skuid"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入商品套餐id"
            clearable
            autocomplete="off"
            v-model="form.skuid"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="支付总金额"
          prop="paytotal"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入支付总金额"
            clearable
            autocomplete="off"
            v-model="form.paytotal"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="折扣码"
          prop="discountcode"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入折扣码"
            clearable
            autocomplete="off"
            v-model="form.discountcode"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="折扣前单价"
          prop="beforeprice"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入折扣前单价"
            clearable
            autocomplete="off"
            v-model="form.beforeprice"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="折后价格"
          prop="discountprice"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入折后价格"
            clearable
            autocomplete="off"
            v-model="form.discountprice"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="国家代码"
          prop="countrycode"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入国家代码"
            clearable
            autocomplete="off"
            v-model="form.countrycode"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="国家名称"
          prop="countryname"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入国家名称"
            clearable
            autocomplete="off"
            v-model="form.countryname"
            size="small"
          />
        </el-form-item>
        <el-form-item label="用户ip" prop="ip" :label-width="formLabelWidth">
          <el-input
            placeholder="请输入用户ip"
            clearable
            autocomplete="off"
            v-model="form.ip"
            size="small"
          />
        </el-form-item>
        <el-form-item label="支付id" prop="payid" :label-width="formLabelWidth">
          <el-input
            placeholder="请输入支付id"
            clearable
            autocomplete="off"
            v-model="form.payid"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="订单状态"
          prop="orderstatus"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入订单状态"
            clearable
            autocomplete="off"
            v-model="form.orderstatus"
            size="small"
          />
        </el-form-item>
        <el-form-item label="备注" prop="remark" :label-width="formLabelWidth">
          <el-input
            placeholder="请输入备注"
            clearable
            autocomplete="off"
            v-model="form.remark"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="退款流水号"
          prop="refundid"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入退款流水号"
            clearable
            autocomplete="off"
            v-model="form.refundid"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="退款金额"
          prop="refundamount"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入退款金额"
            clearable
            autocomplete="off"
            v-model="form.refundamount"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="退款描述"
          prop="refundremark"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入退款描述"
            clearable
            autocomplete="off"
            v-model="form.refundremark"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="服务结束时间"
          prop="endtime"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入服务结束时间"
            clearable
            autocomplete="off"
            v-model="form.endtime"
            size="small"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog()">取 消</el-button>
        <el-button type="primary" @click="submitUser">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  insertOrderinfo,
  updateOrderinfo,
  deleteOrderinfo,
  bacthDelOrderinfo,
  getGoodsSpuAll,
  insertOrderrefund,
} from "@/request/requests";
import { MessageSuccess, MessageError, MessageInfo } from "@/util/util";
export default {
  name: "user",
  data() {
    const statusOptions = [
      {
        value: "1",
        label: "待支付",
      },
      {
        value: "2",
        label: "已支付",
      },
      {
        value: "3",
        label: "已退款",
      },
    ];
    const buytypeOptions = [
      {
        value: "1",
        label: "订阅",
      },
      {
        value: "2",
        label: "兑换码",
      },
      {
        value: "3",
        label: "续费",
      },
    ];
    const columns = [
      { type: "selection" },
      { prop: "id", label: "序号" },
      { prop: "orderid", label: "订单号" },
      { prop: "ticketid", label: "车票id" },
      {
        prop: "buytype",
        label: "购买类型",
        formatter: (row) => this.buytypeState(row),
      },
      // {prop: 'cartype',label: '车辆类型'},
      { prop: "userid", label: "用户id" },
      { prop: "loginemail", label: "用户登录邮箱" },
      { prop: "useremail", label: "用户邮箱" },
      { prop: "goodsid", label: "商品id" },
      { prop: "goodsSpu.goodsname", label: "商品名称" },
      { prop: "goodsSpu.price", label: "商品价格" },
      { prop: "skuid", label: "商品套餐id" },
      { prop: "goodsSku.skuname", label: "商品套餐名称" },
      { prop: "goodsSku.price", label: "商品套餐价格" },
      { prop: "paytotal", label: "支付总金额" },
      { prop: "discountcode", label: "折扣码" },
      { prop: "beforeprice", label: "折扣前单价" },
      { prop: "discountprice", label: "折后价格" },
      { prop: "countrycode", label: "国家代码" },
      { prop: "countryname", label: "国家名称" },
      { prop: "ip", label: "用户ip" },
      { prop: "payid", label: "支付id" },
      { prop: "payorder.paytype", label: "支付方式" },
      {
        prop: "orderstatus",
        label: "订单状态",
        formatter: (row) => this.statusState(row),
      },
      { prop: "remark", label: "备注" },
      { prop: "refundid", label: "退款流水号" },
      { prop: "refundamount", label: "退款金额" },
      { prop: "refundremark", label: "退款描述" },
      { prop: "endtime", label: "服务结束时间" },
      { prop: "createTime", label: "创建时间" },
      { prop: "updateTime", label: "更新时间" },
    ];
    const searchForm = [
      {
        type: "date-picker",
        id: "date",
        label: "订单时间",
        el: {
          type: "daterange",
          valueFormat: "yyyy-MM-dd",
        },
        inputFormat: (row) => {
          if (row.startdate && row.enddate) {
            return [row.startdate, row.enddate];
          }
        },
        outputFormat: (val) => {
          if (!val) {
            return "";
          }
          return {
            startdate: val[0],
            enddate: val[1],
          };
        },
      },
      {
        type: "select",
        id: "goodsid",
        label: "商品名称",
        el: { placeholder: "请输入商品id", clearable: true },
        options: this.spuList,
      },
      {
        type: "input",
        id: "orderid",
        label: "订单号",
        el: { placeholder: "请输入订单号", clearable: true },
      },
      {
        type: "input",
        id: "skuid",
        label: "商品套餐id",
        el: { placeholder: "请输入商品套餐id", clearable: true },
      },
      {
        type: "input",
        id: "ticketid",
        label: "车票id",
        el: { placeholder: "请输入车票id", clearable: true },
      },
      {
        type: "select",
        id: "buytype",
        label: "购买类型",
        el: { placeholder: "请输入购买类型", clearable: true },
        options: buytypeOptions,
      },
      // {
      //   type: 'input',
      //   id: 'cartype',
      //   label: '车辆类型',
      //   el: {placeholder: '请输入车辆类型', clearable: true},
      // },
      {
        type: "input",
        id: "userid",
        label: "用户id",
        el: { placeholder: "请输入用户id", clearable: true },
      },
      {
        type: "input",
        id: "loginemail",
        label: "用户登录邮箱",
        el: { placeholder: "请输入用户登录邮箱", clearable: true },
      },
      {
        type: "input",
        id: "useremail",
        label: "用户邮箱",
        el: { placeholder: "请输入用户邮箱", clearable: true },
      },
      {
        type: "input",
        id: "discountcode",
        label: "折扣码",
        el: { placeholder: "请输入折扣码", clearable: true },
      },
      {
        type: "input",
        id: "countrycode",
        label: "国家代码",
        el: { placeholder: "请输入国家代码", clearable: true },
      },
      {
        type: "input",
        id: "countryname",
        label: "国家名称",
        el: { placeholder: "请输入国家名称", clearable: true },
      },
      {
        type: "input",
        id: "payid",
        label: "支付id",
        el: { placeholder: "请输入支付id", clearable: true },
      },
      {
        type: "select",
        id: "orderstatus",
        label: "订单状态",
        el: { placeholder: "请输入订单状态", clearable: true },
        options: statusOptions,
      },
      {
        type: "input",
        id: "remark",
        label: "备注",
        el: { placeholder: "请输入备注", clearable: true },
      },
      {
        type: "input",
        id: "refundid",
        label: "退款流水号",
        el: { placeholder: "请输入退款流水号", clearable: true },
      },
      {
        type: "input",
        id: "refundremark",
        label: "退款描述",
        el: { placeholder: "请输入退款描述", clearable: true },
      },
    ];
    return {
      columns,
      statusOptions,
      buytypeOptions,
      userinfo: {},
      spuList: [],
      tableConfig: {
        searchForm,
        url: "orderinfo/list",
        dataPath: "data.list",
        totalPath: "data.total",
        // 不现实分页数据
        // hasPagination:false,
        // page:-1,
        // noPaginationSize:20,
        paginationSize: 10,
        extraButtons: [
          {
            type: "primary",
            text: "编辑",
            show: () => {
              return this.hasRole("orderinfo:update");
            },
            atClick: async (row) => {
              this.handleEdit(row);
              return false;
            },
          },
          {
            type: "primary",
            text: "删除",
            show: () => {
              return this.hasRole("orderinfo:delete");
            },
            atClick: async (row) => {
              this.handleDelete(row);
              return false;
            },
          },
          {
            type: "primary",
            text: "用户",
            show: () => {
              return this.hasRole("orderinfo:user");
            },
            atClick: async (row) => {
              this.handleUser(row);
              return false;
            },
          },
          {
            type: "primary",
            text: "车票",
            show: (row) => {
              return this.hasRole("orderinfo:ticket") && row.orderstatus == "2";
            },
            atClick: async (row) => {
              this.handleTicket(row);
              return false;
            },
          },
          {
            type: "primary",
            text: "退款",
            show: (row) => {
              return this.hasRole("orderinfo:refund") && row.orderstatus == "2";
            },
            atClick: async (row) => {
              this.handleRefund(row);
              return false;
            },
          },
          {
            type: "primary",
            text: "退款订单",
            show: (row) => {
              return (
                this.hasRole("orderinfo:refundList") &&
                (row.orderstatus == "3" || row.orderstatus == "2")
              );
            },
            atClick: async (row) => {
              this.handleRefundList(row);
              return false;
            },
          },
        ],
        headerButtons: [
          {
            text: "新增",
            type: "primary",
            show: () => {
              return this.hasRole("orderinfo:insert");
            },
            atClick: async () => {
              this.handleInsert();
              return false;
            },
          },
          {
            text: "批量删除",
            type: "primary",
            show: () => {
              return this.hasRole("orderinfo:batchDelete");
            },
            disabled: (selected) => selected.length < 1,
            atClick: (selected) => {
              let ids = selected.map((item) => item.id);
              this.Batch("delete", ids);
              return false;
            },
          },
        ],
        hasDelete: false,
        hasEdit: false,
        hasOperation: true,
        hasNew: false,
        operationAttrs: {
          width: "150px",
          fixed: "right",
        },
      },
      form: this.initForm(),
      title: "",
      type: "",
      dialogFormVisible: false,
      formLabelWidth: "100px",
      rules: {
        id: [{ required: true, message: "请输入序号", trigger: "blur" }],
        orderid: [{ required: true, message: "请输入订单号", trigger: "blur" }],
        ticketid: [
          { required: true, message: "请输入车票id", trigger: "blur" },
        ],
        cartype: [
          { required: true, message: "请输入车辆类型", trigger: "blur" },
        ],
        userid: [{ required: true, message: "请输入用户id", trigger: "blur" }],
        useremail: [
          { required: true, message: "请输入用户邮箱", trigger: "blur" },
        ],
        goodsid: [{ required: true, message: "请输入商品id", trigger: "blur" }],
        skuid: [
          { required: true, message: "请输入商品套餐id", trigger: "blur" },
        ],
        paytotal: [
          { required: true, message: "请输入支付总金额", trigger: "blur" },
        ],
        discountcode: [
          { required: true, message: "请输入折扣码", trigger: "blur" },
        ],
        beforeprice: [
          { required: true, message: "请输入折扣前单价", trigger: "blur" },
        ],
        discountprice: [
          { required: true, message: "请输入折后价格", trigger: "blur" },
        ],
        countrycode: [
          { required: true, message: "请输入国家代码", trigger: "blur" },
        ],
        countryname: [
          { required: true, message: "请输入国家名称", trigger: "blur" },
        ],
        ip: [{ required: true, message: "请输入用户ip", trigger: "blur" }],
        payid: [{ required: true, message: "请输入支付id", trigger: "blur" }],
        orderstatus: [
          { required: true, message: "请输入订单状态", trigger: "blur" },
        ],
        remark: [{ required: true, message: "请输入备注", trigger: "blur" }],
        refundid: [
          { required: true, message: "请输入退款流水号", trigger: "blur" },
        ],
        refundamount: [
          { required: true, message: "请输入退款金额", trigger: "blur" },
        ],
        refundremark: [
          { required: true, message: "请输入退款描述", trigger: "blur" },
        ],
        endtime: [
          { required: true, message: "请输入服务结束时间", trigger: "blur" },
        ],
        createTime: [
          { required: true, message: "请输入创建时间", trigger: "blur" },
        ],
        updateTime: [
          { required: true, message: "请输入更新时间", trigger: "blur" },
        ],
        ext1: [{ required: true, message: "请输入扩展字段", trigger: "blur" }],
        ext2: [{ required: true, message: "请输入扩展字段", trigger: "blur" }],
        ext3: [{ required: true, message: "请输入扩展字段", trigger: "blur" }],
        ext4: [{ required: true, message: "请输入扩展字段", trigger: "blur" }],
      },
    };
  },
  methods: {
    statusState(row) {
      let data;
      this.statusOptions.forEach((r) => {
        if (r.value == row.orderstatus) {
          data = r.label;
        }
      });
      return data;
    },
    buytypeState(row) {
      let data;
      this.buytypeOptions.forEach((r) => {
        if (r.value == row.buytype) {
          data = r.label;
        }
      });
      return data;
    },
    hasRole(val) {
      return this.hasPerm(val);
    },
    initForm() {
      //初始数据
      return {
        id: "", // 序号
        orderid: "", // 订单号
        ticketid: "", // 车票id
        cartype: "", // 车辆类型
        userid: "", // 用户id
        useremail: "", // 用户邮箱
        goodsid: "", // 商品id
        skuid: "", // 商品套餐id
        paytotal: "", // 支付总金额
        discountcode: "", // 折扣码
        beforeprice: "", // 折扣前单价
        discountprice: "", // 折后价格
        countrycode: "", // 国家代码
        countryname: "", // 国家名称
        ip: "", // 用户ip
        payid: "", // 支付id
        orderstatus: "", // 订单状态
        remark: "", // 备注
        refundid: "", // 退款流水号
        refundamount: "", // 退款金额
        refundremark: "", // 退款描述
        endtime: "", // 服务结束时间
        createTime: "", // 创建时间
        updateTime: "", // 更新时间
        ext1: "", // 扩展字段
        ext2: "", // 扩展字段
        ext3: "", // 扩展字段
        ext4: "", // 扩展字段
      };
    },
    handleEdit(row) {
      console.log(row);
      this.form = row;
      this.openDialog();
      this.title = "修改信息";
      this.type = "update";
    },
    handleUser(row) {
      return this.$router.push({ path: "user", query: { id: row.userid } });
    },
    handleTicket(row) {
      return this.$router.push({ path: "ticket", query: { id: row.ticketid } });
    },
    handleRefund(row) {
      this.$confirmel("此操作将提交退款, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.submitRefund(row);
        })
        .catch(() => {
          MessageInfo("已取消");
        });
    },
    async submitRefund(row) {
      console.log(row);
      let data = {
        orderid: row.orderid,
        userid: row.userid,
        useremail: row.useremail,
        spuid: row.goodsid,
        carid: row.goodsid,
        ticketid: row.ticketid,
        refundstatus: "1", // 退款状态
        countrycode: row.countrycode, // 国家代码
        countryname: row.countryname, // 国家名称
        paytype: row.payorder.paytype, // 支付方式
        refundamount: row.paytotal, // 退款金额
        surplus: 0, // 剩余金额
      };
      let res = await insertOrderrefund(data);
      if (res.status == "200") {
        MessageSuccess(res.msg);
      } else {
        MessageError(res.msg);
      }
    },
    handleRefundList(row) {
      return this.$router.push({
        path: "orderrefund",
        query: { id: row.orderid },
      });
    },
    handleDelete(row) {
      this.$confirmel("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.operatorDeleteUser(row);
        })
        .catch(() => {
          MessageInfo("已取消删除");
        });
    },
    async operatorDeleteUser(row) {
      let res = await deleteOrderinfo(row.id);
      if (res.status == "200") {
        MessageSuccess(res.msg);
      } else {
        MessageError(res.msg);
      }
      this.$refs.table.getList();
    },
    handleInsert() {
      this.openDialog();
      this.title = "新增";
      this.type = "insert";
    },
    async Batch(type, ids) {
      if (type == "delete") {
        let res = await bacthDelOrderinfo(ids);
        if (res.status == "200") {
          MessageSuccess(res.msg);
        } else {
          MessageError(res.msg);
        }
      }
      this.$refs.table.getList();
    },
    openDialog() {
      this.dialogFormVisible = true;
    },
    closeDialog() {
      this.dialogFormVisible = false;
      this.form = this.initForm();
      this.$refs.ruleUser.clearValidate();
      this.$refs.table.getList();
    },
    async submitUser() {
      this.$refs.ruleUser.validate((valid) => {
        if (valid) {
          this.operatorUser();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async operatorUser() {
      let res;
      if (this.type == "insert") {
        res = await insertOrderinfo(this.form);
        if (res.status == "200") {
          MessageSuccess(res.msg);
          this.closeDialog();
        } else {
          MessageError(res.msg);
        }
      } else if (this.type == "update") {
        res = await updateOrderinfo(this.form);
        if (res.status == "200") {
          MessageSuccess(res.msg);
          this.closeDialog();
        } else {
          MessageError(res.msg);
        }
      }
      this.$refs.table.getList();
    },
    getUserInfo() {
      let deadline = sessionStorage.getItem("deadline");
      let userInfo = sessionStorage.getItem("userInfo");
      let token = sessionStorage.getItem("token");
      if (!deadline || !userInfo || !token) {
        this.removeInfo();
        MessageError("请先登录！");
        this.$router.push({ path: "/login" });
        return false;
      } else {
        let now = new Date().getTime();
        if (deadline < now) {
          this.removeInfo();
          MessageError("请先登录！");
          this.$router.push({ path: "/login" });
          return false;
        }
      }
      return userInfo;
    },
    removeInfo() {
      sessionStorage.removeItem("userInfo");
      sessionStorage.removeItem("deadline");
      sessionStorage.removeItem("token");
    },
    async getGoodsSpuAllInfo() {
      let res = await getGoodsSpuAll();
      if (res.status == "200") {
        this.spuList = [];
        res.data.forEach((r) => {
          let data = {
            value: r.id,
            label: r.goodsname,
          };
          this.spuList.push(data);
        });
        this.tableConfig.searchForm[1].options = this.spuList;
      } else {
        MessageError(res.msg);
      }
    },
  },
  created() {
    let userInfo = this.getUserInfo();
    if (userInfo) {
       // 非管理员只展示20条订单数据
       if (!sessionStorage.getItem("userInfo").includes(18)) {
        // 非管理员只有test1能看
       if (!sessionStorage.getItem("userInfo").includes('test1')) {
          this.tableConfig.hasPagination = false
          this.tableConfig.page = -1
          this.tableConfig.noPaginationSize = 20
          }
        }
      this.userinfo = JSON.parse(userInfo);
      this.tableConfig.axiosConfig = {
        headers: {
          Authorization:
            "X-KILOGOD-AUTHORIZATION " + sessionStorage.getItem("token"),
        },
      };
      this.getGoodsSpuAllInfo();
    }
  },
};
</script>

<style scoped></style>
